.navigation {
	position: fixed;
	z-index: 10;
	width: 100%;

	.button {

		padding: 0;
		@include vw(margin, 32px);

		&--info {
			position: absolute;
			opacity: 1;
			animation: fade-in 1.1s 1.6s both;
			right: 0;
			background-color:$black;
			color: $white;

			font-family: $fontFamily;
			font-style: normal;
			font-weight: normal;

			@include vw(font-size, 30px);
			@include vw(line-height, 50px);
			@include vw(border-radius, 50px);
			@include vw(padding-top, 1px);
			@include vw(padding-bottom, 1px);
			@include vw(padding-left, 26px);
			@include vw(padding-right, 26px);

			&:hover {
				background-color: $grey;
			}
		}

		@include max-xs {
			margin: 32px;

			&--info {
				position: fixed;
				bottom: 0;
				font-size: 20px;
				line-height: 44px;
				border-radius: 44px;
				padding: 1px 26px;
			}
		}
	}
}

.button--home {
	position: fixed;
	padding: 0;
	z-index: 30;
	@include vw(margin, 32px);

	animation: fade-in 1.1s 1s both;

	svg {
		@include vw(width, 90px);
		height: auto;
	}

	&:hover {
		svg path {
			fill: $grey;
		}
	}

	@include max-xs {
		margin: 32px;
		max-width: 44px;
	}

}
