.wrapper {
	.box {
		display: flex;
		border-bottom:1px solid $black;
		@include padding-left(40px);
		@include padding-right(40px);
		@include padding-top(40px);
		@include padding-bottom(40px);

		box-sizing: border-box;

		min-height: 80vh;

		&:last-of-type{
			animation: none;
		}
	}

	.info {
		font-family: $fontFamily;
		font-weight: 500;
		font-style: normal;

		&__headline{
			display: inline;
			text-transform: uppercase;

			@include font-size(50px);
			@include line-height(50px);
			@include padding-right(12px);
			@include padding-bottom(4px);

			@include max-xs {
				font-size: 40px;
				line-height: 40px;
				padding-right: 12px;
				padding-bottom: 12px;
			}

			&--small {
				text-transform: none;
			}

			sup {
				text-transform: lowercase;
			}
		}

		&__tags {
			display: inline-block;
		}

		&__subheadline {
			@include font-size(50px);
			@include line-height(50px);

			@include max-xs {
				font-size: 40px;
				line-height: 40px;
			}
		}

		&__date {
			@include padding-top(16px);
			@include padding-bottom(32px);
			@include font-size(30px);
			@include line-height(36px);

			@include max-xs {
				font-size: 22px;
				line-height: 30px;
				padding-top: 18px;
				padding-bottom: 24px;
			}
		}


		&__desc {
			display: inline-block;
			vertical-align: top;
			@include vw(max-width, 640px);
			@include vw(padding-right, 40px);
			@include vw(padding-bottom, 40px);
			@include font-size(18px);
			@include line-height(24px);

			@include max-xs {
				font-size: 16px;
				line-height: 24px;
				padding-right: 0;
				padding-bottom: 0;
				max-width: 100%;
			}

		}

		.banner {
			background-repeat: no-repeat;
			background-size: cover;
			display: inline-block;
			color: transparent;
			overflow: hidden;
			white-space: nowrap;
			@include height(40px);
			@include width(114.74px);

			@include max-xs {
				width: 86px;
				height: 30px;
			}

			&--crea {
				background-image: url(../img/tags/tag--crea.svg);
			}

			&--prod {
				background-image: url(../img/tags/tag--prod.svg);
			}

			&--coprod {
				background-image: url(../img/tags/tag--coprod.svg);
				@include width(124.56px);

				@include max-xs {
					width: calc(124.56px * 0.75);
				}
			}

			&--tour {
				background-image: url(../img/tags/tag--tour.svg);
				@include width(154px);

				@include max-xs {
					width: calc(154px * 0.75);
				}
			}


			&--inovart {

				background-image: url(../img/tags/tag--inovart.svg);
				background-size: cover;
					// @include margin-top(35px);
				max-width: 100%;
				@include width(243.51px);
				@include height(130px);

				@include max-xs {
					margin-top: 24px;
					width: calc(243.51px * 0.75);
					height: calc(130px * 0.75);
				}
			}

		}

		@include max-xs {
			padding: 24px 24px 72px 24px;
			border-bottom: none;

			&:last-of-type{
				height: auto;
			}
		}


	}
}
