/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@keyframes slide-in-bck-center {
	0% {
		transform: translateZ(600px);
		opacity: 0;
	}
	100% {
		transform: translateZ(0);
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation bounce-in-fwd
 * ----------------------------------------
 */
 @keyframes bounce-in-fwd {
	0% {
	  transform: scale(0);
	  animation-timing-function: ease-in;
	  opacity: 0;
	}
	38% {
	  transform: scale(1);
	  animation-timing-function: ease-out;
	  opacity: 1;
	}
	55% {
	  transform: scale(0.7);
	  animation-timing-function: ease-in;
	}
	72% {
	  transform: scale(1);
	  animation-timing-function: ease-out;
	}
	81% {
	  transform: scale(0.84);
	  animation-timing-function: ease-in;
	}
	89% {
	  transform: scale(1);
	  animation-timing-function: ease-out;
	}
	95% {
	  transform: scale(0.95);
	  animation-timing-function: ease-in;
	}
	100% {
	  transform: scale(1);
	  animation-timing-function: ease-out;
	}
}


/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
 @keyframes bounce-in-left {
	0% {
	  transform: translateX(-600px);
	  animation-timing-function: ease-in;
	  opacity: 0;
	}
	38% {
	  transform: translateX(0);
	  animation-timing-function: ease-out;
	  opacity: 1;
	}
	55% {
	  transform: translateX(-68px);
	  animation-timing-function: ease-in;
	}
	72% {
	  transform: translateX(0);
	  animation-timing-function: ease-out;
	}
	81% {
	  transform: translateX(-28px);
	  animation-timing-function: ease-in;
	}
	90% {
	  transform: translateX(0);
	  animation-timing-function: ease-out;
	}
	95% {
	  transform: translateX(-8px);
	  animation-timing-function: ease-in;
	}
	100% {
	  transform: translateX(0);
	  animation-timing-function: ease-out;
	}
}


/**
 * ----------------------------------------
 * animation color-change
 * ----------------------------------------
 */
 @keyframes color-change {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 0 -100%;
	}
}

/**
 * ----------------------------------------
 * animation color-change
 * ----------------------------------------
 */
 @keyframes color-cycle {
	0% {
		height: 0;
	}
	100% {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}
}

