/*********************
 ANIMATIONS
*********************/
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

/*********************
 FONTS
*********************/

$fontFamily: neue-haas-grotesk-display, sans-serif;

/*********************
 COLORS
*********************/

$black: #212121;
$white: #fff;
$grey: #333;

$blue: #1080C9;
$dark-green: #099346;
$light-blue: #1270b5;
$violet: #9C398A;
$light-red: #e53b34;
$pink: #D629A5;
$red: #E30613;
$orange: #ec710c;
$light-orange: #F39200;
$medium-blue: #0277ba;
$dark-orange: #EA5B0B;
$dark-blue: #1080C9;
$light-green: #009244;
$yellow: #FCBD0A;
$light-grey: #9a9998;
$brown: #764e25;
$light-violet: #8e3187;
$turquoise: #4B9297;
$light-yellow: #FCBC0C;

/*********************
 RESPONSIVE
*********************/

$ratio: 0.6663;

$mobile-mockup-width: 375px;
$desktop-mockup-width: 1366px;
$mockup-width: $desktop-mockup-width;






