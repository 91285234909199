@mixin font-size($px) {
	@include vw(font-size, $px);
}

@mixin line-height($px) {
	@include vw(line-height, $px);
}

@mixin height($px) {
	@include vw(height, $px);
}

@mixin width($px) {
	@include vw(width, $px);
}

@mixin margin-top($px) {
	@include vw(margin-top, $px);
}

@mixin top($px) {
	@include vw(top, $px);
}

@mixin left($px) {
	@include vw(left, $px);
}

@mixin right($px) {
	@include vw(right, $px);
}

@mixin bottom($px) {
	@include vw(bottom, $px);
}

@mixin margin-right($px) {
	@include vw(margin-right, $px);
}

@mixin margin-bottom($px) {
	@include vw(margin-bottom, $px);
}

@mixin margin-left($px) {
	@include vw(margin-left, $px);
}

@mixin padding-top($px) {
	@include vw(padding-top, $px);
}

@mixin padding-right($px) {
	@include vw(padding-right, $px);
}

@mixin padding-bottom($px) {
	@include vw(padding-bottom, $px);
}

@mixin padding-left($px) {
	@include vw(padding-left, $px);
}

@mixin vw($prop, $px) {
	$mockup-width: $mockup-width !global;
	$mockup-width: $mockup-width / ($mockup-width - $mockup-width + 1);
	$px-unitless: $px / ($px - $px + 1);
	$responsive-unitless: ($px-unitless / $mockup-width) * 100;
	$responsive: #{$responsive-unitless}vw;
	$min: $px / 2;
	$max: $px;

	$dimension: 'width';
	$min-breakpoint: $min / $responsive-unitless * 100;
	$max-breakpoint: $max / $responsive-unitless * 100;

	/*
	@media (min-#{$dimension}: #{$max-breakpoint}) {
		#{$prop}: $max;
	} */

	#{$prop}: $responsive;
}

@function vw($px, $mockup-width: $mobile-mockup-width){
	@return #{($px / $mockup-width) * 100}vw;
}

@mixin max-xs {
	@media (max-width: 600px) { @content; }
}

@mixin min-sm {
	@media (min-width: 601px) { @content; }
}

@mixin min-md {
	@media (min-width: 800px) { @content; }
}

@mixin min-lg{
	@media (min-width: 1366px) { @content; }
}
