body {
	color: $black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include min-sm {
		cursor: none;
	}
}

.button:hover,
button:hover,
a:hover {
	@include min-sm {
		cursor: none;
	}
}

button {
	background-color: transparent;
	border: none;
}

.button--link {
	display: inline-block;
	text-decoration: none;
	background-color:$black;
	color: $white;

	font-family: $fontFamily;
	font-style: normal;
	font-weight: normal;

	@include font-size(40px);
	@include line-height(50px);
	@include vw(border-radius, 50px);
	@include vw(margin-top, 32px);
	@include vw(margin-bottom, 32px);

	@include padding-top(10px);
	@include padding-bottom(10px);
	@include padding-left(26px);
	@include padding-right(26px);

	&:hover {
		background-color: $grey;
	}

	@include max-xs {
		font-size: 20px;
		line-height: 40px;
		border-radius: 50px;
		margin: 24px 0 0 0;
		padding: 2px 27px;
	}
}

.cursor {
	position: fixed;
	pointer-events: none;
	z-index: 50;
	opacity: 1;
	animation: fade-in 1.1s 1.6s both;

	@include max-xs {
		display: none;
	}

	.cursor--auto{
		display: block;
	}
	.cursor--pointer{
		display: none;
	}

	&.is-hover {
		.cursor--auto{
			display: none;
		}
		.cursor--pointer{
			display: block;
		}
	}

	&.is-click {
		.cursor--auto{
			display: none;
		}
		.cursor--poniter{
			display: block;
		}
	}
}
