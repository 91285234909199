html {
	font-size: 62.5%; // 10px

	@include max-xs {
		overflow-x: hidden;
	}
}

body {
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	scroll-behavior: smooth;
	transition: all 1s;
}

.noscroll {
	overflow: hidden;
	position: absolute;
}

.invisible {
	display: none;
}

.ir {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	white-space: nowrap; /* added line */
}

.intro {
	position: relative;
	background-size: 100% 200%;
	background-image: linear-gradient(to bottom, $white 50%, $blue 50%);
	background-position: 0 -100%;
	overflow: hidden;
	animation: color-change 1s 1 $easeOutQuart;

	&__background {
		position: relative;

		.bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			animation: color-cycle 0.4s 1 $easeOutQuart;
		}
	}

	&__banner {
		display: block;
		background-color: transparent;
		border: none;
		width: 100vw;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);

		display: flex;
		align-items: center;
		justify-content: center;

		@include padding-left(83px);
		@include padding-right(83px);

		perspective: 500px;

		svg {
			animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s both;
		}

		@include max-xs {

			svg path {
				transform: scale(1.3) rotate(-43.16deg);
				transform-origin: 50% 50%;
			}

			padding: 0 13px;
		}
	}

}

.sticky {

	@include min-sm {
		position: sticky !important;
	}
}

.wrapper {
	display: flex;

	@include max-xs {
		display: block;
	}

	&--posters {
		position: relative;
		background-color: #d7d9d9;
		top: 0;
		left: 0;

		width: calc(100vw * #{$ratio});
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);

		@include max-xs {
			width: 100vw;
			height: auto;
			background-color: #fff;
		}

		.poster {
			position: absolute;
			height:100%;
			width:100%;
			top: 0;
			z-index: 1;

			@include max-xs {
				position: relative;
			}

			&__visual {
				width: 100%;
				height: 100vh;
				height: calc(var(--vh, 1vh) * 100);

				display: flex;
				justify-content: center;
				align-items: center;

				@include max-xs {
					height: 100vw;
				}

				img {
					visibility: visible;
					opacity: 0;
					width:100%;
					height:auto;
					transition: opacity 0.5s ease-in-out;

					@include max-xs {
						max-width: 100vw;
						transition: opacity 0.7s ease-in-out;
					}

				}
			}

			&--default {
				z-index: 2;

				img {
					opacity: 1;
				}
			}

			&--active {
				z-index: 3;

				img {
					opacity: 1;
				}
			}

			.info {
				display: none;

				@include max-xs {
					display: block;
				}
			}

		}

	}

	&--infos {
		position: relative;
		width: 100%;

		@include max-xs {
			display: none;
		}
	}
}

.footer {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: right;
	justify-content: right;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	background-color: #fff;

	//transform: translateX(100%);
	left: 100%;
	transition: all 1s;
	bottom:0;
	z-index: 20;
	overflow-x: hidden;
	overflow-y: scroll;

	.button--close {
		position: fixed;
		padding: 0;
		top: 0;
		right: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		svg {
			@include width(52px);
			@include height(52px);

			@include max-xs {
				width: 44px;
				height: 44px;
			}
		}

		@include vw(margin, 32px);

		&:hover .bg {
			fill: $grey;
		}

		@include max-xs {
			margin: 32px;
		}

	}

	&--open {
		left:0;

		.button--close {
			display: block;
			opacity: 1;
		}
	}

	&__wrapper {
		width: 100%;
		@include vw(max-width, 1060px);
		@include vw(margin-top, 124px);
		@include vw(margin-right, 124px);
		margin-left: auto;

		// display: flex;
		// flex-direction: column;
		// align-items: right;

		@include max-xs {
			margin-right: 0;
			margin-top: 96px;
			max-width: 100%;
			padding: 24px;
		}
	}

	&__image {
		width: 100%;

		@include vw(max-width, 1000px);

		@include vw(margin-top, 32px);
		@include vw(margin-bottom, 64px);

		img {
			width: 100%;
			height: auto;
		}

		@include max-xs {
			max-width: 100%;
			margin: 32px 0 64px 0;
		}
	}

	&__credits,
	&__textWrapper {
		width: 100%;

		margin-left: auto;
		@include vw(max-width, 700px);

		@include max-xs {
			max-width: 100%;
		}
	}

	&__credits,
	&__text {
		font-family: $fontFamily;
		font-weight: normal;
		font-style: normal;
		color: black;

		box-sizing: content-box;
		border-top:1px solid $black;

		@include padding-top(8px);
		@include padding-bottom(48px);

		@include font-size(40px);
		@include line-height(60px);

		&__block::before{
			content: '';
			display: inline-block;
			@include width(30px);
			@include height(30px);
			@include vw(margin-right, 5px);
			@include vw(border-radius, 15px);
			background-color: $black;

			@include max-xs {
				width: 14px;
				height: 14px;
				margin-right: 5px;
				border-radius: 7px;
			}
		}

		@include max-xs {
			font-size: 24px;
			line-height: 30px;
			padding: 8px 0 32px 0;
		}

		.link {
			color: $black;
			text-decoration: underline;

			&:visited {
				color: $black;
			}

			&:focus,
			&:hover {
				color: $grey;
			}

		}

		&__headline {
			font-weight: normal;
			@include font-size(30px);
			@include line-height(60px);


			@include max-xs {
				font-size: 18px;
				line-height: 30px;
			}
		}

		&__links {
			.footer__linkBox::before {
				content: '';
				display: inline-block;
				@include width(30px);
				@include height(30px);
				@include vw(margin-right, 5px);
				@include vw(border-radius, 15px);
				background-color: $black;

				@include max-xs {
					width: 14px;
					height: 14px;
					margin-right: 5px;
					border-radius: 7px;
				}
			}

		}

	}

	&__credits {
		border: none;
		@include font-size(30px);
		@include line-height(40px);

		@include max-xs {
			font-size: 18px;
			line-height: 30px;
		}
	}

}


.no-sticky {

	.sticky {
		@include min-sm {
			position: relative !important;
		}
	}

	.wrapper {

		.poster {
			position: static;

			&__visual {
				border-bottom:1px solid $black;

				img {
					opacity: 1;
				}
			}
		}

		.box {
			height: 100vh;
		}
	}
}
