.bg {

	&--white {
		background-color: $white;
	}

	&--dark-green {
		background-color: $dark-green;
	}

	&--light-blue {
		background-color: $light-blue;
	}

	&--dark-blue {
		background-color: $dark-blue;
	}

	&--violet {
		background-color: $violet;
	}

	&--light-red {
		background-color: $light-red;
	}

	&--pink {
		background-color: $pink;
	}

	&--red {
		background-color: $red;
	}

	&--orange {
		background-color: $orange;
	}

	&--light-orange {
		background-color: $light-orange;
	}

	&--medium-blue {
		background-color: $medium-blue;
	}

	&--dark-orange {
		background-color: $dark-orange;
	}

	&--dark-blue {
		background-color: $dark-blue;
	}

	&--light-green {
		background-color: $light-green;
	}

	&--yellow {
		background-color: $yellow;
	}

	&--light-grey {
		background-color: $light-grey;
	}

	&--brown {
		background-color: $brown;
	}

	&--light-violet {
		background-color: $light-violet;
	}

	&--turquoise {
		background-color: $turquoise;
	}

	&--light-yellow {
		background-color: $light-yellow;
	}

}
